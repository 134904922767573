import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/pageHeader';
import { ContentContainer, Sidebar, Main } from '../components/container';
import './__styles__/contact.module.css';

const ContactPage = ({ data }) => {
  const [name, setName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [subject, setSubject] = React.useState([]);

  const subjectOptions = [
    'Information on my home to evaluate flood risk',
    'Site visit for my home to evaluate flood risk',
    'Information on a home I may buy',
    'Information on flood insurance',
    'Information on a home I am looking to build',
    'Information on mitigation grants',
    'Other',
  ];

  return (
    <Layout>
      <SEO
        title="Get Help"
        description="Connect with a local representative who can answer your questions."
      />
      <PageHeader
        title="Get Help"
        subtitle="Connect with a local representative who can answer your questions."
      />
      <ContentContainer>
        <Sidebar>
          <header>Instructions</header>
          <div>
            <p>
              Fill out the questions to the right. After you submit your
              request, a representative from{' '}
              {data.site.siteMetadata.localityNameShort} will follow up to
              respond to your questions.
            </p>
            <p>
              If you would prefer to contact someone by phone, reach out to:
            </p>
            <p>
              <strong>Name:</strong> {data.site.siteMetadata.contactName}
              <br />
              <strong>Phone:</strong> {data.site.siteMetadata.contactPhone}
              <br />
              <strong>Typical Hours:</strong>{' '}
              {data.site.siteMetadata.contactHours}
            </p>
          </div>
        </Sidebar>
        <Main>
          <h2>Flood Assistance Request</h2>
          <form
            styleName="form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="informationRequest"
            method="POST"
            action="/success"
          >
            <input type="hidden" name="form-name" value="informationRequest" />
            <div styleName="hidden">
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </div>

            <label htmlFor="name">What’s your name?</label>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={evt => {
                setName(evt.target.value);
              }}
              placeholder="First and last name"
              required
            />
            <label htmlFor="email">What is your email address?</label>
            <input
              type="email"
              placeholder="name@domain.com"
              name="email"
              id="email"
              value={email}
              onChange={evt => {
                setEmail(evt.target.value);
              }}
              required
            />
            <label htmlFor="phone" styleName="optional">
              What is the best number to reach you at?
            </label>
            <input
              type="tel"
              placeholder="(000) 000-0000"
              name="phone"
              id="phone"
              value={phone}
              onChange={evt => {
                const onlyNumbers = (evt.target.value.match(/\d/g) || []).join(
                  ''
                );
                const parts = [
                  onlyNumbers.slice(0, 3),
                  onlyNumbers.slice(3, 6),
                  onlyNumbers.slice(6),
                ];
                setPhone(
                  `${!!parts[1] ? '(' + parts[0] + ')' : parts[0]}${
                    parts[1] ? ' ' : ''
                  }${parts[1]}${parts[2] ? '-' : ''}${parts[2]}`
                );
              }}
            />
            <label htmlFor="address">What address do you need help with?</label>
            <input
              type="text"
              placeholder="145 Pineland Dr"
              name="address"
              id="address"
              value={address}
              onChange={evt => {
                setAddress(evt.target.value);
              }}
              required
            />
            <fieldset>
              <legend>
                What would you like help with?
                <span styleName="sub">Select all that apply</span>
              </legend>
              <div styleName="radio-group">
                {subjectOptions.map(option => (
                  <label key={option}>
                    <input
                      type="checkbox"
                      name="subject[]"
                      value={option}
                      checked={subject.includes(option)}
                      onChange={evt => {
                        let newSubject;
                        if (evt.target.checked) {
                          newSubject = _.concat(subject, [option]);
                        } else {
                          newSubject = _.filter(subject, s => s !== option);
                        }
                        setSubject(newSubject);
                      }}
                      required={!subject.length}
                    />
                    <span>{option}</span>
                  </label>
                ))}
              </div>
            </fieldset>

            <button type="submit">Submit request</button>
          </form>
        </Main>
      </ContentContainer>
    </Layout>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contactName
            contactPhone
            contactHours
            localityNameShort
          }
        }
      }
    `}
    render={data => <ContactPage data={data} />}
  />
);
